const htmlMainFont = document.documentElement.dataset.mainfont;
const htmlMaterialFont = document.documentElement.dataset.materialfont;

window.WebFontConfig = {
    google: {
        families: [`${htmlMainFont}:latin,latin-ext`, htmlMaterialFont],
    },
    active() {
        document.cookie = `fontsLoaded=1; expires=${(new Date(new Date().getTime() + 86400000)).toGMTString()}; path=/`;
    },
};

const el = document.documentElement;
el.className += ' wf-loading';

setTimeout(() => {
    el.className = el.className.replace(/(^|\s)wf-loading(\s|$)/g, ' ');
}, 1000); // 1 second
